import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// import 'firebase/storage';
import cheerIcon from '../../images/cheer.svg';
// import { firestore } from './plugins/firebase';

class Cheers extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);
    console.log("here");
    this.state = {
      cheers:null,
      loading: false,
    };
  }

  firebaseInit = () => {
    console.log(this.props.firebase);
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
          console.log("here1");
      console.log(this.props.firebase);
      this.onListenForMessages();
    }else{
        console.log("here2");
      }
  };

  componentDidMount() {
    this.firebaseInit();
  }
  //
  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages(){
    this.setState({ loading: true });
// console.log(this.props.firebase);
    this.props.firebase
      .cheers()
      // .orderByChild('')
      .limitToLast(1)
      .on('value', snapshot => {
        const cheerObject = snapshot.val();
console.log(cheerObject);
        if (cheerObject) {
          const cheerList = Object.keys(cheerObject).reverse().map(key => ({
            ...cheerObject[key],
            uid: key,
          }));
          console.log(cheerList);
                    console.log(cheerList[0].count);
          this.setState({
            cheers: cheerObject.count,
            loading: false,
          });

        } else {
          this.setState({ cheers: null, loading: false });
        }
      });
  };

  onCheer = (authUser) => {
    this._initFirebase = false;
  this.props.firebase.cheers("count").set({
    count: this.state.cheers + 1
  });
}

  render() {
const { cheers, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="cheerlayout">
          <div className="cheerText">応援総数</div>
          <div className="cheerNum">{cheers}</div>
          <div className="cheerBtn">
            <div
              onClick={() => this.onCheer(authUser)}
              className="btn-circle-3d"
            >
              <img className="cheericon" alt="cheer" src={cheerIcon} />
            </div>
            <div className="cheersubtext">タップして応援しよう！</div>
          </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Cheers);
