import React, { Component } from 'react';
import { navigate } from 'gatsby';

import { withFirebase } from '../Firebase';
import lock from '../../images/loginlock.svg';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        navigate(ROUTES.OB);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="signform">
      <div className="signboard">
      <div className="locktitle">
      <img alt="lock" className="lockImg" src={lock} />
      <div className="logintitle">ログインページ</div>
      </div>
      <form className="loginform" onSubmit={this.onSubmit}>
      <div>
        <input
          className="logintext"
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="メールアドレス"
        />
        </div>
        <div>
        <input
        className="logintext"
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="パスワード"
        />
        </div>
        <div>
        <button className="loginbtn" disabled={isInvalid} type="submit">
          LOGIN
        </button>
</div>
      </form>
      </div>
      </div>
    );
  }
}


const SignInForm = withFirebase(SignInFormBase);

export default SignInForm;
